import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import ShrtQuery from '../../../../components/common/ShrtQuery';
import ShrtSpinner from '../../../../components/common/Spinner';
import ShrtLinkDetails from '../../../../components/links/ShrtLinkDetails';
import { ApiKey } from '../../../../models/api-key';
import { LinkStatisticsPeriod } from '../../../../models/link';
import { apiKeysSelector, selectApiKey, selectedApiKeySelector } from '../../../../redux/reducers/api-keys/api-keys-reducer';
import { getMyLink } from '../../../../services/shortenify.service';
import { formatDay, isAfter, isBefore, parseDay, startOfWeek, today } from '../../../../utils/DateUtils';
import { getParam } from '../../../../utils/UrlUtils';

type UpdatedParameters = {
  from?: string;
  period?: LinkStatisticsPeriod;
  to?: string;
};

const transformDate = (date: string): Date => parseDay(date).toDate();
const transformPeriod = (period: string): LinkStatisticsPeriod => (period === 'monthly' ? LinkStatisticsPeriod.Monthly : LinkStatisticsPeriod.Daily);

const getUpdatedSearchParameters = (searchParams: URLSearchParams, options?: UpdatedParameters) => {
  const initialFromDate = startOfWeek(true);
  const initialPeriod = LinkStatisticsPeriod.Daily;
  const initialToDate = today();
  const fromDate = getParam<Date>(searchParams, 'from', transformDate, initialFromDate);
  const period = getParam<LinkStatisticsPeriod>(searchParams, 'period', transformPeriod, initialPeriod);
  const toDate = getParam<Date>(searchParams, 'to', transformDate, initialToDate);
  return {
    period: options?.period ?? period,
    from: options?.from ?? formatDay(fromDate),
    to: options?.to ?? formatDay(toDate),
  };
};

export default function ShrtLink() {
  const dispatch = useDispatch();
  const location = useLocation();
  let initialFromDate = startOfWeek(true);
  const initialPeriod = LinkStatisticsPeriod.Daily;
  const initialToDate = today();
  // Case where the user comes from dashboard by clicking on "More statistics" button
  const passedDownLink = location.state?.link?.id;
  let [searchParams, setSearchParams] = useSearchParams(getUpdatedSearchParameters(new URLSearchParams()));
  const [, , apiKeyParam, , linkParam] = location.pathname.split('/');
  const fromDate = getParam<Date>(searchParams, 'from', transformDate, initialFromDate);
  const period = getParam<LinkStatisticsPeriod>(searchParams, 'period', transformPeriod, initialPeriod);
  const toDate = getParam<Date>(searchParams, 'to', transformDate, initialToDate);
  const myApiKeys = useSelector(apiKeysSelector);
  const selectedApiKey = useSelector(selectedApiKeySelector);

  useEffect(() => {
    const validateApiKey = async (apiKeys: ApiKey[]) => {
      const existingApiKey = apiKeys?.find((key) => key.id === apiKeyParam);
      if (existingApiKey?.id && selectedApiKey === null) {
        dispatch(selectApiKey(existingApiKey));
      }
    };
    if (!selectedApiKey) {
      validateApiKey(myApiKeys);
    }
  }, [apiKeyParam, dispatch, myApiKeys, selectedApiKey]);

  const handleOnChangeFromDate = (date: Date) => {
    const toDate = getParam<Date>(searchParams, 'to', transformDate, initialToDate);
    if (!isAfter(date, toDate)) {
      setSearchParams((previousSearchParams) => getUpdatedSearchParameters(previousSearchParams, { from: formatDay(date) }));
    }
  };

  const handleOnChangePeriod = (period: LinkStatisticsPeriod) => {
    setSearchParams((previousSearchParams) => getUpdatedSearchParameters(previousSearchParams, { period }));
  };

  const handleOnChangeToDate = (date: Date) => {
    const fromDate = getParam<Date>(searchParams, 'from', transformDate, initialFromDate);
    if (!isBefore(date, fromDate)) {
      setSearchParams((previousSearchParams) => getUpdatedSearchParameters(previousSearchParams, { to: formatDay(date) }));
    }
  };

  // Case of a refresh on that page
  if (!selectedApiKey) {
    return <ShrtSpinner />;
  }

  return (
    <ShrtQuery
      initialData={undefined}
      errorLabelId="pages.link.errors.link_not_found"
      query={() => getMyLink(selectedApiKey.id, passedDownLink ?? linkParam, fromDate, toDate, period)}
      queryKey={`link#${passedDownLink ?? linkParam}#${period}#${fromDate.toISOString()}#${toDate.toISOString()}`}
    >
      {(data) => (
        <ShrtLinkDetails
          fromDate={fromDate}
          initialFromDate={initialFromDate}
          initialToDate={initialToDate}
          link={data.getLink}
          onChangeFromDate={handleOnChangeFromDate}
          onChangeToDate={handleOnChangeToDate}
          onChangePeriod={handleOnChangePeriod}
          period={period}
          toDate={toDate}
        />
      )}
    </ShrtQuery>
  );
}
