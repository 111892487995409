import { Bar } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { LinkStatistics, LinkStatisticsPeriod } from '../../../models/link';
import { formatDay, formatMonth, parseDay, parseMonth } from '../../../utils/DateUtils';

const DAILY_DATE_FORMAT = 'll';
const MONTHLY_DATE_FORMAT = 'MMMM';

type ShrtLinkStatisticsLineChartProps = {
  from: string;
  period: LinkStatisticsPeriod;
  statistics: LinkStatistics[];
  to: string;
};

export default function ShrtLinkStatisticsLineChart({ from, period, statistics, to }: ShrtLinkStatisticsLineChartProps) {
  const intl = useIntl();
  return (
    <Bar
      data={{
        labels: extractLabels(from, to, period),
        datasets: [
          {
            label: intl.formatMessage({
              id: 'pages.link.labels.statistics_chart_title',
              defaultMessage: '# of opened',
            }),
            data: extractValues(statistics, from, to, period),
            borderWidth: 1,
          },
        ],
      }}
      options={{ scales: { x: { grid: { display: false } }, y: { grid: { display: true }, ticks: { stepSize: 1 } } } }}
    />
  );
}

const extractLabels = (from: string, to: string, period: LinkStatisticsPeriod) => {
  const start = period === LinkStatisticsPeriod.Daily ? parseDay(from) : parseMonth(from);
  const end = period === LinkStatisticsPeriod.Daily ? parseDay(to) : parseMonth(to);
  const labels = [period === LinkStatisticsPeriod.Daily ? formatDay(start.toDate(), DAILY_DATE_FORMAT) : formatMonth(start.toDate(), MONTHLY_DATE_FORMAT)];
  while (start.isBefore(end)) {
    start.add(1, period === LinkStatisticsPeriod.Daily ? 'day' : 'month');
    labels.push(period === LinkStatisticsPeriod.Daily ? formatDay(start.toDate(), DAILY_DATE_FORMAT) : formatMonth(start.toDate(), MONTHLY_DATE_FORMAT));
  }
  return labels;
};

const extractValues = (items: LinkStatistics[] = [], from: string, to: string, period: LinkStatisticsPeriod) => {
  const start = period === LinkStatisticsPeriod.Daily ? parseDay(from) : parseMonth(from);
  const end = period === LinkStatisticsPeriod.Daily ? parseDay(to) : parseMonth(to);
  const values = [];
  const date = period === LinkStatisticsPeriod.Daily ? formatDay(start.toDate()) : formatMonth(start.toDate());
  values.push(items.find((item) => item.date === date)?.opened ?? 0);
  while (start.isBefore(end)) {
    start.add(1, period === LinkStatisticsPeriod.Daily ? 'day' : 'month');
    const date = period === LinkStatisticsPeriod.Daily ? formatDay(start.toDate()) : formatMonth(start.toDate());
    values.push(items.find((item) => item.date === date)?.opened ?? 0);
  }
  return values;
};
