import { Label, Select } from 'flowbite-react';
import { ChangeEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export type ShrtSelectOption = {
  labelId: string;
  labelDefaultMessage: string;
  labelValues: any;
  id: string;
  value: string;
};

type ShrtSelectProps = {
  defaultOption: string;
  options: ShrtSelectOption[];
  onSelectOptionHandler: (value: any) => void;
};

export default function ShrSelect({ defaultOption, onSelectOptionHandler, options }: ShrtSelectProps) {
  const intl = useIntl();

  const handleSelectedApiKey: ChangeEventHandler<HTMLSelectElement> = (e) => {
    e.preventDefault();
    onSelectOptionHandler(e.target.value);
  };

  return (
    <div className="max-w-md">
      {/* <div className="mb-2 block">
        <Label
          htmlFor="keys"
          value={intl.formatMessage({
            id: labelId,
            defaultMessage: labelDefaultMessage,
          })}
        />
      </div> */}
      <Select id="keys" onChange={handleSelectedApiKey} defaultValue={defaultOption}>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            <FormattedMessage id={option.labelId} defaultMessage={option.labelDefaultMessage} values={option.labelValues} />
          </option>
        ))}
      </Select>
    </div>
  );
}
